import React, { useState } from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import theme from "../../moodyTheme";

// Custom hash function
const customHash = (input: string): string => {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return hash.toString(16); // Convert to hexadecimal
};

// Precomputed hash for the valid password
const validPasswordHashes = ["-6b750eb1", "46db3112"];

const ReservationLogin: React.FC = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    // setIsAuthenticated(true);
    const hashedPassword = customHash(password);
    if (validPasswordHashes.includes(hashedPassword)) {
      setIsAuthenticated(true);
      setError("");
    } else {
      setError("Invalid password. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  if (isAuthenticated) {
    const ReserveHandlePage = React.lazy(() => import("./reserveHandle"));
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <ReserveHandlePage />
      </React.Suspense>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: theme.colors.moodyDarkBackground,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "2rem",
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          backgroundColor: "#1A1A1D",
          padding: "2rem",
          borderRadius: "10px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
          color: theme.colors.moodyWhite,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Quicksand, sans-serif",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          Login to Reserve Handle
        </Typography>
        {error && (
          <Alert severity="error" sx={{ marginBottom: "1rem" }}>
            {error}
          </Alert>
        )}
        <TextField
          label="Temporary Password (from email we sent)"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={togglePasswordVisibility}
                  edge="end"
                  sx={{ color: theme.colors.moodyWhite }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: theme.colors.moodyWhite },
          }}
          sx={{
            input: {
              color: theme.colors.moodyWhite,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.colors.moodyWhite,
              },
              "&:hover fieldset": {
                borderColor: theme.colors.moodyBlue,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.colors.moodyBlue,
              },
            },
          }}
        />
        <Button
          variant="contained"
          onClick={handleLogin}
          sx={{
            width: "100%",
            marginTop: "1rem",
            padding: "0.8rem",
            backgroundColor: theme.colors.moodyBlue,
            color: theme.colors.moodyDarkBackground,
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
            fontSize: "16px",
            "&:hover": {
              backgroundColor: theme.colors.darkButtonHover,
            },
          }}
        >
          Login
        </Button>
      </Container>
    </Box>
  );
};

export default ReservationLogin;
